import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useCurrentWeather from "@micado-digital/react-weather/hooks/useCurrentWeather";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import useFetch from "@micado-digital/react-ui/hooks/useFetch";
import Overlay from "./Overlay";

import styles from "./Quicklinks.styles";

const Quicklinks = ({ lang, layoutVariant, season, setOverlayOpen }) => {
	const { REACT_APP_PATH } = process.env;
	const [quicklinkID, setQuicklinkID] = useState(null);
	const current = useCurrentWeather({
		lang: "de",
		station: 3,
		url: `${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WeatherApi/GetDetails.api`
	});
	const { state, temperature } = current || {};
	// const [values, setValues] = useState([]);
	const [countOpenLifts, setCountOpenLifts] = useState(0);
	const [countOpenPists, setCountOpenPists] = useState(0);
	const isDesktop = useMediaQuery(theme => theme.breakpoints.up("lg"));
	const sysLink = useSystemLinks();
	const css = styles();

	// const response = useFetch(
	// 	`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.PropertyApi/ListProperties.api?lang=${lang}&region=stjohann&location=st-johann-bergbahn&client=https://sgm.bergbahnen-stjohann.at`
	// );

	const responseLifts = useFetch(
		`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api?lang=${lang}&region=stjohann&location=stjohann&client=https://sgm.bergbahnen-stjohann.at&type=lift`
	);

	const responsePists = useFetch(
		`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api?lang=${lang}&region=stjohann&location=stjohann&client=https://sgm.bergbahnen-stjohann.at&type=piste`
	);

	const handleClick = id => {
		setQuicklinkID(id);
		setOverlayOpen(true);
	};

	useEffect(() => {
		if (quicklinkID === null) {
			setOverlayOpen(false);
		}
	}, [quicklinkID, setOverlayOpen]);

	// useEffect(() => {
	// 	const { computedProperties } = response;

	// 	if (!computedProperties || computedProperties.length === 0) return;

	// 	const listComputedProperties = ["lift", "piste"];

	// 	setValues(
	// 		listComputedProperties?.map(item => {
	// 			return computedProperties?.find(p => p.identifier === item);
	// 		})
	// 	);
	// }, [response]);

	useEffect(() => {
		const { count } = responseLifts;

		if (count && count?.open) {
			setCountOpenLifts(count?.open);
		}
	}, [responseLifts]);

	useEffect(() => {
		const { count } = responsePists;

		if (count && count?.lengthOpen) {
			const rounded = Math.round(count?.lengthOpen / 1000);

			setCountOpenPists(rounded);
		}
	}, [responsePists]);

	return (
		<>
			<div
				className={clsx("mco-quicklinks", css.root, {
					[css.fullscreen]: layoutVariant === "fullscreen"
				})}
			>
				<div
					className={clsx("mco-quicklinks__item-weather", css.item, {
						"mco-quicklinks__item--selected": quicklinkID === 1
					})}
					onClick={() => handleClick(1)}
				>
					<ReactSVGIcon
						src={`/img/icons/weather/${state ?? "D"}.svg`}
						size={isDesktop && layoutVariant === "fullscreen" ? 32 : 24}
					/>
					<span className={css.label}>{temperature}°C</span>
				</div>
				{season === "winter" && (
					<div
						className={clsx("mco-quicklinks__item-slope", css.item, {
							"mco-quicklinks__item--selected": quicklinkID === 3
						})}
						onClick={() => handleClick(3)}
					>
						<ReactSVGIcon
							src="/img/icons/ski/slope.svg"
							size={isDesktop && layoutVariant === "fullscreen" ? 32 : 24}
						/>
						{countOpenPists !== 0 && <span className={css.label}>{countOpenPists} km</span>}
					</div>
				)}
				<div
					className={clsx("mco-quicklinks__item-facilities", css.item, {
						"mco-quicklinks__item--selected": quicklinkID === 2
					})}
					onClick={() => handleClick(2)}
				>
					<ReactSVGIcon
						src="/img/icons/ski/cablecar.svg"
						size={isDesktop && layoutVariant === "fullscreen" ? 32 : 24}
					/>
					{countOpenLifts !== 0 && <span className={css.label}>{countOpenLifts}</span>}
				</div>
				<div className={clsx("mco-quicklinks__item-map", css.item)}>
					<ReactLink
						to={season === "winter" ? sysLink("map") : sysLink("map-summer")}
						target="_blank"
					>
						<ReactSVGIcon
							src="/img/icons/map.svg"
							size={isDesktop && layoutVariant === "fullscreen" ? 40 : 32}
						/>
					</ReactLink>
				</div>
				<div className={clsx("mco-quicklinks__item-webcams", css.item)}>
					<ReactLink to={sysLink("webcams") || "/"}>
						<ReactSVGIcon
							src="/img/icons/webcam.svg"
							size={isDesktop && layoutVariant === "fullscreen" ? 40 : 32}
						/>
					</ReactLink>
				</div>
			</div>
			<Overlay setQuicklinkID={setQuicklinkID} quicklinkID={quicklinkID} season={season} />
		</>
	);
};

export default Quicklinks;
